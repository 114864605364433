<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Name (En)" vid="name" rules="required">
              <b-form-group
                label-for="org_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.name')}} {{$t('globalTrans.en')}} <span class="text-danger">*</span>
              </template>
              <b-form-input
                plain
                v-model="form.name"
                id="name"
                :state="errors[0] ? false : (valid ? true : null)"
              >
              </b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
              <b-form-group
                label-for="org_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.name')}} {{$t('globalTrans.bn')}} <span class="text-danger">*</span>
              </template>
              <b-form-input
                plain
                v-model="form.name_bn"
                id="name_bn"
                :state="errors[0] ? false : (valid ? true : null)"
              >
              </b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Organization Name (En)" vid="org_name_en" rules="required">
              <b-form-group
                label-for="org_name_en"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.organization')}} {{$t('globalTrans.en')}} <span class="text-danger">*</span>
              </template>
              <b-form-input
                plain
                v-model="form.org_name_en"
                id="org_name_en"
                :state="errors[0] ? false : (valid ? true : null)"
              >
              </b-form-input>
              <div class="invalid-feedback">
                  {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Organization Name (Bn)" vid="org_name_bn" rules="required">
              <b-form-group
                label-for="org_name_bn"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.organization')}} {{$t('globalTrans.bn')}} <span class="text-danger">*</span>
              </template>
              <b-form-input
                plain
                v-model="form.org_name_bn"
                id="org_name_bn"
                :state="errors[0] ? false : (valid ? true : null)"
              >
              </b-form-input>
              <div class="invalid-feedback">
                    {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Designation (En)" vid="designation_en" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="designation_en">
                <template v-slot:label>
                  {{ $t('globalTrans.designation') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="designation_en"
                  v-model="form.designation_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Designation (Bn)" vid="designation_bn" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="designation_bn">
                <template v-slot:label>
                  {{ $t('globalTrans.designation') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="designation_bn"
                  v-model="form.designation_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Mobile" vid="mobile" rules="required|max:11|min:11" v-slot="{ errors }">
              <b-form-group
                label-for="mobile">
                <template v-slot:label>
                  {{ $t('globalTrans.mobile') }}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="mobile"
                  v-model="form.mobile"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Email" vid="email" rules="required|email" v-slot="{ errors }">
              <b-form-group
                label-for="email">
                <template v-slot:label>
                  {{ $t('globalTrans.email') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="email"
                  v-model="form.email"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Photo" vid="image" :rules="form.id ? '' : 'required'">
              <b-form-group
                class="row"
                label-cols-sm="12"
                :label="$t('globalTrans.photo')"
                label-for="image"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.photo')}} <span class="text-danger">*</span>
                </template>
                <b-form-file
                    id="image"
                    accept="image/*"
                    v-model="form.image"
                    v-on:change="onChangeFile"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-file>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { guestTrainerStore, guestTrainerUpdate } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      form: {
        name: '',
        name_bn: '',
        designation_en: '',
        designation_bn: '',
        org_name_en: '',
        org_name_bn: '',
        mobile: '',
        email: '',
        image: []
      },
      imageFile: []
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getMeasurementUnit()
      this.form = tmp
    }
  },
  watch: {
    'form.training_type_id': function (newValue) {
        this.trainingCategory = this.categoryList(newValue)
    },
    'form.org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.trainingTypeList = this.getTrainingTypeList(newVal)
      } else {
        this.trainingTypeList = []
      }
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    orgList () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    }
  },
  methods: {
    categoryList (typeId) {
       const category = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return category.filter(item => item.training_type_id === parseInt(typeId))
       }
       return category
    },
    onChangeFile (e) {
      this.imageFile = e.target.files[0]
    },
    getTrainingTypeList (id) {
      const gttl = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1 && item.org_id === id)
      return gttl
    },
    async saveUpdate () {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      var formData = new FormData()
      Object.keys(this.form).map(key => {
        if (key === 'image') {
          formData.append('image', this.imageFile)
        } else {
          formData.append(key, this.form[key])
        }
      })
      if (this.id) {
        formData.append('_method', 'POST')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${guestTrainerUpdate}/${this.id}`, formData, config)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, guestTrainerStore, formData, config)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
         this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getMeasurementUnit () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
